import React from "react";
import ProductSimilarEndustriyelKagit from "../Components/product/ProductSimilarEndustriyelKagit";
import EndustriyelKagitZKatHavluEko from "../Components/product/EndustriyelKagitZKatHavluEko";

function EndustriyelKagitZKatHavluEkoPage() {
  return (
    <>
      <EndustriyelKagitZKatHavluEko />
      <ProductSimilarEndustriyelKagit />
    </>
  );
}

export default EndustriyelKagitZKatHavluEkoPage;
