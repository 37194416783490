import React from "react";
import ProductSimilarGida from "../Components/product/ProductSimilarGida";
import GidaSu from "../Components/product/GidaSu";

function GidaSuPage() {
  return (
    <>
      <GidaSu />
      <ProductSimilarGida />
    </>
  );
}

export default GidaSuPage;
