/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";

function GidaCaykurFilizCay() {
  const { t } = useTranslation();

  return (
    <>
      <SRLWrapper>
        <section className="page-header">
          <div
            className="cta-two__bg"
            style={{
              backgroundImage: "url(assets/images/shapes/footer-bg-1.webp)",
            }}
          ></div>

          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="index.html">{t("Anasayfa")}</a>
              </li>
              <li>
                <span>{t("ÜRÜNLERİMİZ")}</span>
              </li>
            </ul>
            <h2 className="page-header__title">{t("ÇAYKUR FİLİZ ÇAY")}</h2>
          </div>
        </section>
        <section className="product">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-xl-6 wow fadeInLeft"
                data-wow-delay="200ms"
              >
                <div className="product-details__img">
                  <img src="assets/images/gida/caykur-filiz-cay.webp" alt={t("ÇAYKUR FİLİZ ÇAY")} />
                  <div className="product-details__img-search">
                    <a
                      className="img-popup"
                      href="assets/images/gida/caykur-filiz-cay.webp"
                    ></a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-6 wow fadeInRight"
                data-wow-delay="300ms"
              >
                <div className="product-details__content">
                  <div className="product-details__top">
                    <h3 className="product-details__title">
                      {t("ÇAYKUR FİLİZ ÇAY")}
                      <span className="product-details__price"></span>
                    </h3>
                  </div>
                  <div className="product-details__review">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="product-details__divider"></div>
                  <div className="product-details__excerpt">
                    <p className="list-unstyled product-details__description__lists">
                      <ul className="list-unstyled product-details__description__lists">
                        <li>
                          <span className="icon-right-arrow"></span>
                          {t("Bilgilendirme")}:
                        </li>
                        {t("Çaykur Filiz Çay, Türkiye'nin önde gelen çay üreticilerinden biri olan Çaykur tarafından üretilen bir çay markasıdır. Filiz Çayı, özellikle Türkiye'de yaygın olarak tüketilen ve sevilen bir çay markasıdır.Filiz Çayı, siyah çay kategorisine aittir. Türk çay kültüründe önemli bir yer tutan siyah çay, geleneksel demleme yöntemleriyle hazırlanır. Çaykur Filiz Çay, genellikle demliğe veya çaydanlığa konularak demlenir. Türk kültüründe çay, genellikle ince belli çay bardaklarında ve çay fincanlarında sıcak olarak tüketilir.")}
                        <li>
                          <span className="icon-right-arrow"></span>
                          {t("Marka İmajı")}:
                        </li>
                        {t("Çaykur Filiz Çayı, kaliteli çay üretimi ve güvenilir bir marka imajıyla bilinir. Türkiye genelinde ve uluslararası düzeyde çay tüketicileri arasında popülerdir.Filiz Çayı genellikle lezzetli, koyu renkli ve karakteristik bir Türk çayı tadına sahiptir. Çay kültürü, Türkiye'de sosyal bir aktivite olarak önemli bir yer tutar, ve Filiz Çayı bu kültüre katkıda bulunan bir markadır.")}
                      </ul>
                    </p>
                  </div>

                  <div className="product-details__socials">
                    <h4 className="product-details__socials__title">
                      {t("ARKADAŞLARINLA PAYLAŞ")}
                    </h4>
                    <a href="https://twitter.com/X">
                      <span className="fab fa-twitter"></span>
                    </a>
                    <a href="https://www.facebook.com/">
                      <span className="fab fa-facebook"></span>
                    </a>
                    <a href="https://www.pinterest.com/">
                      <span className="fab fa-pinterest-p"></span>
                    </a>
                    <a href="https://www.instagram.com/">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SRLWrapper>
    </>
  );
}

export default GidaCaykurFilizCay;
