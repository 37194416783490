import React from "react";
import ProductSimilarEndustriyelKagit from "../Components/product/ProductSimilarEndustriyelKagit";
import EndustriyelKagitMiniJumboTuvaletKagidi from "../Components/product/EndustriyelKagitMiniJumboTuvaletKagidi";

function EndustriyelKagitMiniJumboTuvaletKagidiPage() {
  return (
    <>
      <EndustriyelKagitMiniJumboTuvaletKagidi />
      <ProductSimilarEndustriyelKagit />
    </>
  );
}

export default EndustriyelKagitMiniJumboTuvaletKagidiPage;
