import React from "react";
import ProductSimilarKozmetik from "../Components/product/ProductSimilarKozmetik";
import YogunBronzlastiriciYag from "../Components/product/YogunBronzlastiriciYag";

function YogunBronzlastiriciYagPage() {
  return (
    <>
      <YogunBronzlastiriciYag />
      <ProductSimilarKozmetik  />
    </>
  );
}

export default YogunBronzlastiriciYagPage;
