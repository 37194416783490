import React from "react";
import ProductSimilarGida from "../Components/product/ProductSimilarGida";
import GidaCaykurKamelyaCay from "../Components/product/GidaCaykurKamelyaCay";


function GidaCaykurKamelyaCayPage() {
  return (
    <>
      <GidaCaykurKamelyaCay />
      <ProductSimilarGida />
    </>
  );
}

export default GidaCaykurKamelyaCayPage;
