import React from "react";
import ProductSimilarGida from "../Components/product/ProductSimilarGida";
import GidaSoda from "../Components/product/GidaSoda";

function GidaSodaPage() {
  return (
    <>
      <GidaSoda />
      <ProductSimilarGida />
    </>
  );
}

export default GidaSodaPage;
