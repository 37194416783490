import React from "react";
import ProductSimilarEndustriyelKagit from "../Components/product/ProductSimilarEndustriyelKagit";
import EndustriyelKagitIctenCekmeliKagitHavluEko from "../Components/product/EndustriyelKagitIctenCekmeliKagitHavluEko";

function EndustriyelKagitIctenCekmeliKagitHavluEkoPage() {
  return (
    <>
      <EndustriyelKagitIctenCekmeliKagitHavluEko />
      <ProductSimilarEndustriyelKagit />
    </>
  );
}

export default EndustriyelKagitIctenCekmeliKagitHavluEkoPage;
