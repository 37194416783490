import React from "react";
import ProductSimilarEndustriyelKagit from "../Components/product/ProductSimilarEndustriyelKagit";
import EndustriyelKagitIctenCekmeliKagitHavlu from "../Components/product/EndustriyelKagitIctenCekmeliKagitHavlu";

function EndustriyelKagitIctenCekmeliKagitHavluPage() {
  return (
    <>
      <EndustriyelKagitIctenCekmeliKagitHavlu />
      <ProductSimilarEndustriyelKagit />
    </>
  );
}

export default EndustriyelKagitIctenCekmeliKagitHavluPage;
