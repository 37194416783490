import React from "react";
import ProductSimilarTemizlik from "../Components/product/ProductSimilarTemizlik";
import TemizlikCamasirSuyu5kg from "../Components/product/TemizlikCamasirSuyu5kg";

function TemizlikCamasirSuyu5kgPage() {
  return (
    <>
      <TemizlikCamasirSuyu5kg />
      <ProductSimilarTemizlik />
    </>
  );
}

export default TemizlikCamasirSuyu5kgPage;
