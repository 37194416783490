import React from "react";
import ProductSimilarEndustriyelKagit from "../Components/product/ProductSimilarEndustriyelKagit";
import EndustriyelKagitMiniJumboTuvaletKagidiEko from "../Components/product/EndustriyelKagitMiniJumboTuvaletKagidiEko";

function EndustriyelKagitMiniJumboTuvaletKagidiEkoPage() {
  return (
    <>
      <EndustriyelKagitMiniJumboTuvaletKagidiEko />
      <ProductSimilarEndustriyelKagit />
    </>
  );
}

export default EndustriyelKagitMiniJumboTuvaletKagidiEkoPage;
