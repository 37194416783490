// AmbalajAluminyumFolyoKisa.js
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useTranslation } from "react-i18next";
import { SRLWrapper } from "simple-react-lightbox";

function TemizlikGenelTemizlikMaddesi() {
  const { t } = useTranslation();

  return (
    <>
      <SRLWrapper>
        <section className="page-header">
          <div
            className="cta-two__bg"
            style={{
              backgroundImage: "url(assets/images/shapes/footer-bg-1.webp)",
            }}
          ></div>

          <div className="container">
            <ul className="page-header__breadcrumb list-unstyled">
              <li>
                <a href="index.html">{t("Anasayfa")}</a>
              </li>
              <li>
                <span>{t("ÜRÜNLERİMİZ")}</span>
              </li>
            </ul>
            <h2 className="page-header__title">
              {t("GENEL TEMİZLİK MADDESİ")}
            </h2>
          </div>
        </section>
        <section className="product">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-xl-6 wow fadeInLeft"
                data-wow-delay="200ms"
              >
                <div className="product-details__img">
                  <img
                    src="assets/images/endustriyeltemizlik/genel-temizlik-maddesi-5lt.webp"
                    alt="GENEL TEMİZLİK MADDESİ"
                  />
                  <div className="product-details__img-search">
                    <a
                      className="img-popup"
                      href="assets/images/endustriyeltemizlik/genel-temizlik-maddesi-5lt.webp"
                    ></a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-6 wow fadeInRight"
                data-wow-delay="300ms"
              >
                <div className="product-details__content">
                  <div className="product-details__top">
                    <h3 className="product-details__title">
                      {t("GENEL TEMİZLİK MADDESİ")}
                      <span className="product-details__price">
                        {t("5KG-20KG")}
                      </span>
                    </h3>
                  </div>
                  <div className="product-details__review">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="product-details__divider"></div>
                  <div className="product-details__excerpt">
                    <p className="list-unstyled product-details__description__lists">
                      <ul className="list-unstyled product-details__description__lists">
                        <li>
                          <span className="icon-right-arrow"></span>
                          {t("Özellikleri")}
                        </li>
                        {t(
                          "Seramik, mermer, marley, fayans gibi tüm sert ve silinebilir yüzeylerin temizliğinde kullanılabilir. Yüzeyleri çizmez, kolay durulanır. Güçlü ve hoş kokusuyla ferahlık verir. Ekonomiktir."
                        )}
                        <li>
                          <span className="icon-right-arrow"></span>
                          {t("Mamül Bileşimi")}
                        </li>
                        {t(
                          "Noniyonik Yüzey Aktif Madde, Organik Çözücüler %1-5. Koruyucu Madde, %40 Alkol, Boya ve Parfüm içerir."
                        )}
                        <li>
                          <span className="icon-right-arrow"></span>
                          {t("Kullanım Şekli Ve Miktarı")}
                        </li>
                        {t(
                          "Mermer, marley, fayans, seramik, kapı ve tüm boyalı yüzeyler için 5 Lt. suya (yarım kova) 50 Gr. Ürün ilave edilir. Çok kirli yüzeylerde ise, sulandırmadan ıslak bir bez üzerine dökülerek kullanılır."
                        )}
                        <li>
                          <span className="icon-right-arrow"></span>
                          {t("Tüketicinin Dikkatine")}
                        </li>
                        {t(
                          "Kullanma talimatını mutlaka okuyunuz. El, yüz, vücut ve gıda maddelerinin temizliğinde kullanmayınız. Cildinizi korumak için, ellerin ürünle uzun süre temasından kaçınınız. Kapakta bulunan emniyet pimi kırılmış bidonları satın almayınız. Çocuklardan uzak tutunuz. Donmaktan koruyunuz. Üstüste en fazla 2 bidon istifleyiniz."
                        )}
                      </ul>
                    </p>
                  </div>

                  <div className="product-details__socials">
                    <h4 className="product-details__socials__title">
                      {t("ARKADAŞLARINLA PAYLAŞ")}
                    </h4>
                    <a href="https://twitter.com/X">
                      <span className="fab fa-twitter"></span>
                    </a>
                    <a href="https://www.facebook.com/">
                      <span className="fab fa-facebook"></span>
                    </a>
                    <a href="https://www.pinterest.com/">
                      <span className="fab fa-pinterest-p"></span>
                    </a>
                    <a href="https://www.instagram.com/">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SRLWrapper>
    </>
  );
}

export default TemizlikGenelTemizlikMaddesi;
