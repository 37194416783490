import React from "react";
import ProductSimilarGida from "../Components/product/ProductSimilarGida";
import GidaCaykurTiryakiCay from "../Components/product/GidaCaykurTiryakiCay";

function GidaCaykurTiryakiCayPage() {
  return (
    <>
      <GidaCaykurTiryakiCay />
      <ProductSimilarGida />
    </>
  );
}

export default GidaCaykurTiryakiCayPage;
