import React from "react";
import ProductSimilarEndustriyelKagit from "../Components/product/ProductSimilarEndustriyelKagit";
import EndustriyelKagitHareketliKagitHavlu from "../Components/product/EndustriyelKagitHareketliKagitHavlu";

function EndustriyelKagitHareketliKagitHavluPage() {
  return (
    <>
      <EndustriyelKagitHareketliKagitHavlu />
      <ProductSimilarEndustriyelKagit />
    </>
  );
}

export default EndustriyelKagitHareketliKagitHavluPage;
