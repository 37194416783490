import React from "react";
import ProductSimilarGida from "../Components/product/ProductSimilarGida";
import GidaKupSeker from "../Components/product/GidaKupSeker";

function GidaKupSekerPage() {
  return (
    <>
      <GidaKupSeker />
      <ProductSimilarGida />
    </>
  );
}

export default GidaKupSekerPage;
