import React from "react";
import ProductSimilarGida from "../Components/product/ProductSimilarGida";
import GidaCaykurFilizCay from "../Components/product/GidaCaykurFilizCay";

function GidaCaykurFilizCayPage() {
  return (
    <>
      <GidaCaykurFilizCay />
      <ProductSimilarGida />
    </>
  );
}

export default GidaCaykurFilizCayPage;
